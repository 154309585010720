// src/App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import './App.css';

// Globe Animation Component
const GlobeAnimation = () => {
  useEffect(() => {
    const canvas = document.getElementById('scene');
    canvas.width = canvas.clientWidth;
    canvas.height = canvas.clientHeight;

    if (window.devicePixelRatio > 1) {
      canvas.width = canvas.clientWidth * 2;
      canvas.height = canvas.clientHeight * 3;
    }

    const ctx = canvas.getContext('2d');
    let width = canvas.clientWidth;
    let height = canvas.clientHeight;
    let rotation = 0;
    let dots = [];
    const DOTS_AMOUNT = 1000;
    const DOT_RADIUS = 4;
    let GLOBE_RADIUS = width * 0.7;
    let GLOBE_CENTER_Z = -GLOBE_RADIUS;
    let PROJECTION_CENTER_X = width / 2;
    let PROJECTION_CENTER_Y = height / 2;
    let FIELD_OF_VIEW = width * 0.8;

    class Dot {
      constructor(x, y, z) {
        this.x = x;
        this.y = y;
        this.z = z;

        this.xProject = 0;
        this.yProject = 0;
        this.sizeProjection = 0;
      }

      project(sin, cos) {
        const rotX = cos * this.x + sin * (this.z - GLOBE_CENTER_Z);
        const rotZ = -sin * this.x + cos * (this.z - GLOBE_CENTER_Z) + GLOBE_CENTER_Z;
        this.sizeProjection = FIELD_OF_VIEW / (FIELD_OF_VIEW - rotZ);
        this.xProject = (rotX * this.sizeProjection) + PROJECTION_CENTER_X;
        this.yProject = (this.y * this.sizeProjection) + PROJECTION_CENTER_Y;
      }

      draw(sin, cos) {
        this.project(sin, cos);
        ctx.beginPath();
        ctx.arc(this.xProject, this.yProject, DOT_RADIUS * this.sizeProjection, 0, Math.PI * 2);
        ctx.closePath();
        ctx.fill();
      }
    }

    function createDots() {
      dots.length = 0;

      for (let i = 0; i < DOTS_AMOUNT; i++) {
        const theta = Math.random() * 2 * Math.PI;
        const phi = Math.acos((Math.random() * 2) - 1);

        const x = GLOBE_RADIUS * Math.sin(phi) * Math.cos(theta);
        const y = GLOBE_RADIUS * Math.sin(phi) * Math.sin(theta);
        const z = (GLOBE_RADIUS * Math.cos(phi)) + GLOBE_CENTER_Z;
        dots.push(new Dot(x, y, z));
      }
    }

    function render(a) {
      ctx.clearRect(0, 0, width, height);

      rotation = a * 0.0004;

      const sineRotation = Math.sin(rotation);
      const cosineRotation = Math.cos(rotation);

      for (var i = 0; i < dots.length; i++) {
        dots[i].draw(sineRotation, cosineRotation);
      }

      window.requestAnimationFrame(render);
    }

    function afterResize() {
      width = canvas.offsetWidth;
      height = canvas.offsetHeight;
      if (window.devicePixelRatio > 1) {
        canvas.width = canvas.clientWidth * 2;
        canvas.height = canvas.clientHeight * 2;
      } else {
        canvas.width = width;
        canvas.height = height;
      }
      GLOBE_RADIUS = width * 0.7;
      GLOBE_CENTER_Z = -GLOBE_RADIUS;
      PROJECTION_CENTER_X = width / 2;
      PROJECTION_CENTER_Y = height / 2;
      FIELD_OF_VIEW = width * 0.8;

      createDots();
    }

    let resizeTimeout;

    function onResize() {
      resizeTimeout = window.clearTimeout(resizeTimeout);
      resizeTimeout = window.setTimeout(afterResize, 500);
    }

    window.addEventListener('resize', onResize);

    createDots();
    window.requestAnimationFrame(render);
  }, []);

  return (
    <canvas id="scene"></canvas>
  );
};

// Landing Page Component
const LandingPage = () => {
  const [showGlobe, setShowGlobe] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowGlobe(false);
    }, 5000000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="landing-page">
      {showGlobe && <GlobeAnimation />}

      <Link to="/app">
        <button>Enter the Sikh Literature App</button>
      </Link>
    </div>
  );
};

// Main App Component
const App = () => {
  useEffect(() => {
    document.title = 'Sikh Reading List';
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route
          path="/app"
          element={
            <div>
              <header className="header">
                <h1>Sikh Literature</h1>
              </header>
              <div className="iframe-container">
                {/* Set the width to '100%' and height to '100vh', with max-width for containment */}
                <iframe
                  src="https://v2-embednotion.com/4bdbed0edff7464fa0df5d84b39c2d8c?v=b1da6e731c4f446d8145f56e3e1ceda9"
                  title="Sikh Reading List"
                  frameBorder="0"
                  style={{
                    width: '100vw',
                    height: '100vh',
                    maxWidth: '99%',
                    border: '2px solid #ccc',
                    borderRadius: '10px',
                    padding: 'none',
                    margin: '0 auto',
                  }}
                ></iframe>
              </div>
              <footer className="footer">
                <div className="cta-box">
                  <h2>Contact me</h2>
                  <p>
                    Have questions or suggestions?<br />Feel free to reach out!
                  </p>
                  <a
                    href="mailto:sikhreadsio@gmail.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Send Email <span role="img" aria-label="mail emoji">📧</span>
                  </a>
                </div>
              </footer>
            </div>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
